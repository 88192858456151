<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-form ref="formRef" label-width="80px" :model="state.form">
      <el-form-item label="群组名称">
        <el-input
          v-model="state.form.name"
          disabled
          placeholder="请输入群组名称，方便区分投放群组"
        />
      </el-form-item>
      <el-form-item label="群组备注">
        <div class="d-flex a-center">
          <el-upload
            action="#"
            accept=".xlsx"
            :auto-upload="false"
            :file-list="state.fileList"
            :on-change="changeFile"
            :limit="1"
          >
            <el-button type="primary">导入数据</el-button>
          </el-upload>
          <span
            class="ml-20 cursor-pointer text-primary"
            @click="handleDownload"
          >
            下载导入模板
          </span>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">确认投放</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref, getCurrentInstance } from 'vue'
  import { importTicket, doloadTicket } from '@/api/exhibitionManage'
  import Bus from '@/utils/bus'
  import { debounce } from '@/utils/debounce'
  const { proxy } = getCurrentInstance()
  const props = defineProps({
    title: {
      type: String,
      default: '导入并投放',
    },
  })
  const dialogFormVisible = ref(false)
  const formRef = ref(null)
  // const emit = defineEmits(['noticeThrowDiallog'])
  const state = reactive({
    form: {
      batchNo: '',
      file: '',
      name: '',
    },
    fileList: [],
  })
  const handleReset = () => {
    formRef.value.resetFields()
    state.form = {
      batchNo: '',
      file: '',
      name: '',
    }
    state.fileList = []
  }
  const handleClose = () => {
    handleReset()
    dialogFormVisible.value = false
  }
  const handleOpen = (row = {}) => {
    state.form = {
      batchNo: row.batchNo || '',
      file: '',
      name: row.name || '',
    }
    dialogFormVisible.value = true
  }
  const handleImport = () => {
    console.log('导入数据')
  }
  const changeFile = (val) => {
    let nameArray = val.name.split('.')
    if (nameArray[nameArray.length - 1] === 'xlsx') {
      state.form.file = val.raw
    } else {
      proxy.$baseMessage(
        `操作表格格式错误,请确认表格内容并重新上传成功`,
        'error',
        'vab-hey-message-error'
      )
    }
  }
  const handleDownload = () => {
    let a = document.createElement('a')
    a.href =
      'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/images/template.xlsx'
    a.download = '文件模板.xlsx'
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
  const handleSave = debounce(async () => {
    if (!state.form.file)
      return proxy.$baseMessage(
        `请先上传文件`,
        'warning',
        'vab-hey-message-warning'
      )
    Bus.$emit('noticeTicketsThrowDialog', {
      file: state.form.file,
      batchNo: state.form.batchNo,
    })
    handleClose()
  })
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
